import gql from 'graphql-tag'


const listResponse = `
  id name
`

export const LIST_DISTRICT = (templateType) => gql`query LIST_DISTRICT ($countryType: String!, $q: FilterInput) {
  districts: listCountry${templateType}District (countryType: $countryType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id name provinceId
`

export const DETAIL_DISTRICT = (templateType) => gql`query DETAIL_DISTRICT ($countryType: String!, $districtId: Int!) {
  district: detailCountry${templateType}District (countryType: $countryType, districtId: $districtId) {${detailResponse}}
}`

export const CREATE_DISTRICT = (templateType) => gql`mutation CREATE_DISTRICT ($countryType: String!, $input: Country${templateType}DistrictInput!) {
  createDistrict: createCountry${templateType}District (countryType: $countryType, input: $input) {${detailResponse}}
}`

export const UPDATE_DISTRICT = (templateType) => gql`mutation UPDATE_DISTRICT ($countryType: String!, $districtId: Int!, $input: Country${templateType}DistrictInput!) {
  updateDistrict: updateCountry${templateType}District (countryType: $countryType, districtId: $districtId, input: $input) {${detailResponse}}
}`

export const DESTROY_DISTRICT = (templateType) => gql`mutation DESTROY_DISTRICT ($countryType: String!, $districtId: Int!) {
  destroyDistrict: destroyCountry${templateType}District (countryType: $countryType, districtId: $districtId) {id}
}`


export const LIST_DISTRICT_PROVINCE = (templateType) => gql`query LIST_DISTRICT_PROVINCE ($countryType: String!, $q: FilterInput) {
  items: listCountry${templateType}DistrictProvince (countryType: $countryType, q: $q) {
    id name
  }
}`
