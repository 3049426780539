import gql from 'graphql-tag'


const listResponse = `
  id name isDefault
`

export const LIST_COUNTRY = (templateType) => gql`query LIST_COUNTRY ($countryType: String!, $q: FilterInput) {
  countries: listCountry${templateType} (countryType: $countryType, q: $q) {${listResponse}}
}`

export const DETAIL_COUNTRY = (templateType) => gql`query DETAIL_COUNTRY ($countryType: String!, $countryId: Int!) {
  country: detailCountry${templateType} (countryType: $countryType, countryId: $countryId) {${listResponse}}
}`

export const CREATE_COUNTRY = (templateType) => gql`mutation CREATE_COUNTRY ($countryType: String!, $input: Country${templateType}Input!) {
  createCountry: createCountry${templateType} (countryType: $countryType, input: $input) {${listResponse}}
}`

export const UPDATE_COUNTRY = (templateType) => gql`mutation UPDATE_COUNTRY ($countryType: String!, $countryId: Int!, $input: Country${templateType}Input!) {
  updateCountry: updateCountry${templateType} (countryType: $countryType, countryId: $countryId, input: $input) {${listResponse}}
}`

export const DESTROY_COUNTRY = (templateType) => gql`mutation DESTROY_COUNTRY ($countryType: String!, $countryId: Int!) {
  destroyCountry: destroyCountry${templateType} (countryType: $countryType, countryId: $countryId) {id}
}`
