import gql from 'graphql-tag'


const listResponse = `
  id name
`

export const LIST_PROVINCE = (templateType) => gql`query LIST_PROVINCE ($countryType: String!, $q: FilterInput) {
  provinces: listCountry${templateType}Province (countryType: $countryType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id name countryId
`

export const DETAIL_PROVINCE = (templateType) => gql`query DETAIL_PROVINCE ($countryType: String!, $provinceId: Int!) {
  province: detailCountry${templateType}Province (countryType: $countryType, provinceId: $provinceId) {${detailResponse}}
}`

export const CREATE_PROVINCE = (templateType) => gql`mutation CREATE_PROVINCE ($countryType: String!, $input: Country${templateType}ProvinceInput!) {
  createProvince: createCountry${templateType}Province (countryType: $countryType, input: $input) {${detailResponse}}
}`

export const UPDATE_PROVINCE = (templateType) => gql`mutation UPDATE_PROVINCE ($countryType: String!, $provinceId: Int!, $input: Country${templateType}ProvinceInput!) {
  updateProvince: updateCountry${templateType}Province (countryType: $countryType, provinceId: $provinceId, input: $input) {${detailResponse}}
}`

export const DESTROY_PROVINCE = (templateType) => gql`mutation DESTROY_PROVINCE ($countryType: String!, $provinceId: Int!) {
  destroyProvince: destroyCountry${templateType}Province (countryType: $countryType, provinceId: $provinceId) {id}
}`


export const LIST_PROVINCE_COUNTRY = (templateType) => gql`query LIST_PROVINCE_COUNTRY ($countryType: String!, $q: FilterInput) {
  items: listCountry${templateType}ProvinceCountry${templateType} (countryType: $countryType, q: $q) {
    id name isDefault
  }
}`
