import gql from 'graphql-tag'


const listResponse = `
  id name zipCode
`

export const LIST_SUBDISTRICT = (templateType) => gql`query LIST_SUBDISTRICT ($countryType: String!, $q: FilterInput) {
  subdistricts: listCountry${templateType}Subdistrict (countryType: $countryType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id name zipCode districtId
`

export const DETAIL_SUBDISTRICT = (templateType) => gql`query DETAIL_SUBDISTRICT ($countryType: String!, $subdistrictId: Int!) {
  subdistrict: detailCountry${templateType}Subdistrict (countryType: $countryType, subdistrictId: $subdistrictId) {${detailResponse}}
}`

export const CREATE_SUBDISTRICT = (templateType) => gql`mutation CREATE_SUBDISTRICT ($countryType: String!, $input: Country${templateType}SubdistrictInput!) {
  createSubdistrict: createCountry${templateType}Subdistrict (countryType: $countryType, input: $input) {${detailResponse}}
}`

export const UPDATE_SUBDISTRICT = (templateType) => gql`mutation UPDATE_SUBDISTRICT ($countryType: String!, $subdistrictId: Int!, $input: Country${templateType}SubdistrictInput!) {
  updateSubdistrict: updateCountry${templateType}Subdistrict (countryType: $countryType, subdistrictId: $subdistrictId, input: $input) {${detailResponse}}
}`

export const DESTROY_SUBDISTRICT = (templateType) => gql`mutation DESTROY_SUBDISTRICT ($countryType: String!, $subdistrictId: Int!) {
  destroySubdistrict: destroyCountry${templateType}Subdistrict (countryType: $countryType, subdistrictId: $subdistrictId) {id}
}`


export const LIST_SUBDISTRICT_DISTRICT = (templateType) => gql`query LIST_SUBDISTRICT_DISTRICT ($countryType: String!, $q: FilterInput) {
  items: listCountry${templateType}SubdistrictDistrict (countryType: $countryType, q: $q) {
    id name
  }
}`
